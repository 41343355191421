import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.PhotoGallery = Backbone.View.extend({
    className: 'flexslider',

    initialize: function () {
      this.render();
    },

    render: function () {
      this.$el.append($('<ul>', {'class': 'slides'}))
      this.appendPhotos();
    },

    initiateSlider: function (start_img_id) {
      this.$el.flexslider({
        animation: 'slide',
        startAt: start_img_id,
        controlNav: this._controlNav(),
        directionNav: this._directionNav()
      });
      // Replace previous/next text with font awesome icons
      this.$('.flex-prev').text('').append($('<i>', {'class': 'fa fa-angle-left'}))
      this.$('.flex-next').text('').append($('<i>', {'class': 'fa fa-angle-right'}))
    },

    appendPhotos: function () {
      this.collection.each(function (photo) {
        var img = $('<li data-thumb="' + photo.get('thumb') + '">').
          append($('<img>', {src: photo.get('url')}));
        this.$('ul').append(img)
      }, this)
    },

    _controlNav: function(){
      return (this.collection.length == 1) ? false : 'thumbnails'
    },

    _directionNav: function(){
      return this.collection.length != 1;
    }
  })
})
